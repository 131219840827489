@import "atom.scss";
@import "markdown.scss";
@import "syntax.scss";
@import "toc.scss";

:root {
  --pm: #0fa0ce;
  --bd: #e1e1e1;
  --bg: #fff;
  --ft: #222;
  --tag: #333;

  --w-mobile: 640px;

  --global-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

html.dark {
  --bg: #181818;
  --ft: silver;
  --bd: #555;
  --tag: #999;

  --ovo-ft: var(--ft);
  --ovo-bdr: var(--bd);
  --ovo-bg: var(--bg);
  --ovo-bg-hvr: #555;
  --ovo-tag: #30363d;
}

body {
  max-width: var(--w-mobile);
  margin: 3rem auto 0;
  font-family: var(--global-font-family);
  font-size: 16px;
  font-weight: 400;
  color: var(--ft);
  background-color: var(--bg);
  transition-property: background-color, border-color, color;
  transition-duration: 0.5s;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Noto Serif SC", 'Times New Roman', Times, sans-serif;
  font-weight: 700;
  line-height: 1.5;
}

p {
  line-height: 1.8;
  text-align: justify;
}

hr {
  border-width: 0;
  border-top: 1px solid var(--bd);
}

pre,
code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 13px;
}

.minima-tag,
p code {
  background-color: var(--bd);
  padding: 0.2em 0.4em;
  border-radius: 1px;
}

a {
  line-height: 1.5;
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: var(--pm);
}

main {
  animation: showup 0.7s;
}

p a {
  font-style: italic;
  text-decoration: underline;
}

footer a {
  font-style: unset;
  text-decoration: none;
}

header nav {
  color: var(--tag);
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.7);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(128, 128, 128, 0.2);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgb(128, 128, 128);
}

h1::-webkit-scrollbar,
nav::-webkit-scrollbar {
  display: none;
  width: 0;
}

@keyframes showup {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.home-intro {
  line-height: 1.65;
}